.container {
  padding: 2rem 1rem 0rem 1rem;
  background: white;
}

.container .heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  font-family: "Merriweather";
  margin-bottom: 3rem;
}

.container .card {
  padding: 4rem;
  text-align: center;
}

.container .cardSm .cardHeading {
  font-size: 1.8rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 2.5rem;
}

.container .cardSm .cardHeading .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container .additional {
  margin-top: 4rem;
}

.container .additional .rowCard {
  display: flex;
  gap: 2rem;
  font-size: 1.4rem;
  align-items: center;
  margin-top: 2rem;
}

.container .cardSm .cardHeading button {
  border: none;
  background-color: #00486d;
  color: white;
  font-size: 14px;
  padding: 0.8rem 1rem;
  font-weight: 600;
  border-radius: 5px;
}

.container .cardSm .iconContainer svg {
  width: 30px;
  /* display: none; */
}

.container .cardSm .cardContent {
  margin-top: 1rem;
  font-size: 1.5rem;
}

.container .card .cardMessage {
  font-size: 1.3rem;
}

.container .card.card1 {
  background-color: #e4f1f8;
}

.container .card.card1 .cardHeading {
  color: #141414;
}

.container .card.card2 {
  background-color: #0177b5;
  color: white;
}

.container .card.card3 {
  background-color: #00486d;
  color: white;
}

.container .additionalBtn {
  border: none;
  background-color: #00486d;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (min-width: 960px) {
}
