.container {
  padding: 4rem 3rem;
}

.lg {
  display: none;
}

.container .heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
  font-family: "Merriweather", sans-serif;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 30%; /* Full-width */
  height: 5px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 32px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: #d3d3d3; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: #d0d0d0; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.container .sliderInputContainer {
  display: flex;
  justify-content: center;
}

.container .importanceList {
  display: none;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 8rem 12rem 8rem 12rem;
  }
  .lg {
    display: block;
  }

  .sm {
    display: none;
  }

  .container .heading {
    font-size: 3.8rem;
    margin-bottom: 4rem;
  }

  .container .sliderContainer {
    display: none;
  }

  .container .importanceList {
    display: flex;
    /* width: 90%; */
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    gap: 4rem;
  }
}
