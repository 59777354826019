.footer {
  width: 100%;
  background: #00486d;
  color: white;
  padding: 4rem 3rem 2rem 3rem;
}

.footer .linkContainer {
  display: flex;
}

.footer .linkContainer .linkSubContainer {
  width: 50%;
}

.footer .textLogo {
  display: none;
}

.footer .footerLogoContainer {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .mobileSocialIcon {
  display: flex;
  gap: 1rem;
}

.footer .footerLogoContainer .footerLogo {
  width: 15%;
}

.footer .linkItem {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: #ffffff;
}

.linkedin {
  margin-top: 1rem !important;
}

.footer .demoBtn {
  padding: 0.7rem 2rem;
  background-color: white;
  color: #01486d;
  border-radius: 7px;
  border: none;
  font-weight: 700;
  font-size: 1.5rem;
}

.footer .copyRightContainer {
  margin-top: 4rem;
  border-top: solid;
  border-top-width: 0.5px;
  border-top-color: #dbdbdb;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.footer .copyRight {
  text-align: center;
  font-size: 0.9rem;
}

.footer .socialIcons {
  display: none;
}

.footer .actionsContainer {
  display: flex;
  margin-top: 4rem;
  align-items: center;
}

.footer .spacer {
  width: 50%;
}

@media screen and (min-width: 960px) {
  .footer {
    padding: 4rem 12rem 2rem 12rem;
  }

  .footer .container {
    display: flex;
    justify-content: space-between;
  }

  .footer .actionsContainer {
    display: block;
    margin-top: 0;
  }

  .footer .subContainer {
    width: 70%;
  }

  .footer .textLogo {
    display: block;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .footer .spacer {
    width: auto;
  }

  .footer .linkItem {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  .linkedin {
    margin-top: 2rem !important;
  }

  .footer .footerLogo,
  .footer .footerLogoContainer {
    display: none;
  }

  .footer .copyRight {
    text-align: left;
    font-size: 1.2rem;
  }
  .footer .copyRightContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer .socialIcons {
    display: flex;
    gap: 1rem;
  }
}
