.accordionBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

.accordionBtn .accordionTitle {
  font-size: 1.7rem;
  width: 80%;
  font-weight: 600;
  margin-bottom: 0;
}

.accordion {
  margin-bottom: 4rem;
}

.accordion .accordionMessage {
  position: relative;
}

.accordion .textBar {
  position: absolute;
  height: 100%;
  width: 5px;
  background: #0277b5;
  border-radius: 30px;
}

.accordion .accordionMessage p {
  margin-left: 1.4rem;
  font-size: 1.3rem;
}

.accordion .bodyContainer {
  margin: 0 2rem;
  transition: all;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 4rem 20rem;
  }

  .accordion .bodyContainer {
    width: 80%;
  }

  .accordionBtn .accordionTitle {
    font-size: 2rem;
  }

  .accordion .accordionMessage p {
    margin-left: 1.7rem;
    font-size: 1.5rem;
  }
}
