.container {
  padding: 6rem 0rem 6rem 3rem;
  background-color: #0277b5;
  color: white;
  position: relative;
}

.container .heading {
  font-size: 2.5rem;
  font-weight: 500;
}

.container .slideHalf {
  width: 90%;
  margin-right: 0;
  margin-left: auto;
}

.container .slideHalf .content {
  font-size: 1.8rem;
  line-height: 3rem;
  width: 80%;
}

.container .contentBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: #00486d;
  border: none;
  border-radius: 30px;
  padding: 1.5rem 5rem;
  margin-top: 3rem;
  font-size: 1.5rem;
}

.container .slideHalf .imgContainer {
  width: 100%;
  height: 25rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.container .slideHalf .imgContainer img {
  width: 100%;
  height: 100%;
}

.container .slideHalf .contentBtn {
  display: none;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 4rem 15rem 17rem 15rem;
    display: flex;
    gap: 10rem;
  }

  .container .heading {
    font-size: 3rem;
    width: 20%;
    font-weight: 400;
  }

  .container .contentBtn {
    display: none;
  }

  .container .slideHalf .contentBtn {
    display: block;
    margin-left: 0;
  }

  .container .slideHalf .content {
    width: 90%;
  }

  .container .slideHalf .imgContainer {
    width: 60%;
    height: 30rem;
    position: absolute;
    bottom: 0;
    left: 20%;
    margin-bottom: 0rem;
    transform: translateY(50%);
  }
}
