.container {
  background-color: white;
  top: 0%;
  right: -100%;
  width: 100%;
  height: 100vh;
  position: fixed;
  padding: 10% 8%;
  transition: right 1s ease-in-out;
  z-index: 5;
}

.container.open {
  right: 0%;
}

.container .logoSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container .mainSection {
  min-height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .mainSection .linkItem,
.subLinks .linkItem {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: black;
}

.container .subLinkItem {
  text-decoration: none;
}

.container .subLinkItem p {
  color: #bebdbd;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  /* background-color: red; */
}

.container .mainSection .linkItem p,
.subLinks .linkItem p {
  margin-bottom: 2rem;
  text-align: center;
}

.container .subLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px #dbdbdb;
  padding-top: 3rem;
}

.container .demoBtn {
  padding: 1rem 2rem;
  background-color: #00486d;
  color: white;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  font-size: 1.6rem;
  margin-left: auto;
  margin-right: auto;
}
