.container {
    padding: 4rem 6rem;
    background-color: #f9f9f9;
}

.container p{
    color: black;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 20px;
}

.container p a{
    color: #0077B5;
}

.container h3{
    color: black;
    font-size: 35px;
    font-family: "Merriweather";
    font-weight: 700;
    line-height: 93px;
    word-wrap: break-word
}

@media (max-width: 767px) {
    .container h3{
        font-size: 25px;
        line-height: 53px;
    }

    .container p{
        font-size: 15px;
        line-height: 30px;
    }
}