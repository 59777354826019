.statMainContainer {
  padding: 4rem 3rem;
  background-color: #f9f9f9;
}

.statMainContainer.isHome {
  background-color: white;
  padding-top: 0;
}

.statMainContainer .heading_container {
  margin-bottom: 5rem;
}

.statMainContainer .heading_container .heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

.statMainContainer .heading_container .heading .highlight {
  background: #e4f1f8;
}

.statMainContainer .statContainer {
  display: flex;
  flex-direction: column;
}

.container .statContainer .statItem {
  display: flex;
  gap: 3rem;
  padding-left: 2rem;
  padding-right: 3rem;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  /* flex-direction: row; */
}

.statMainContainer .statContainer .statItem.statMiddleItem1 {
  border-top: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.statMainContainer .statContainer .statItem.statMiddleItem2 {
  border-bottom: solid 1px #dbdbdb;
}

.statMainContainer .statContainer .statItem .stat {
  width: 40%;
  text-align: right;
  color: #01486d;
  font-size: 3.5rem;
  font-weight: 700;
  font-family: "Merriweather", serif;
}

.statMainContainer .statContainer .statItem .info {
  font-size: 1.7rem;
  width: 55%;
}

.statMainContainer .statContainer .statItem .info.first {
  width: 40%;
}

.statMainContainer .statContainer .statItem .info.last {
  width: 70%;
}

.statMainContainer .statContainer .statItem .state.last {
  width: 30%;
}

.statMainContainer .statContainer .statItem {
  display: flex;
  gap: 1rem;
  padding-left: 2rem;
  padding-right: 3rem;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.statMainContainer .heading {
  text-align: center;
  font-size: 2rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
  margin-bottom: 0rem;
}

@media screen and (min-width: 960px) {
  .statMainContainer {
    padding: 4rem 12rem;
    background-color: #f9f9f9;
  }

  .statMainContainer .heading_container {
    margin-bottom: 5rem;
    width: 80%;
    margin-top: 2.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .statMainContainer .heading.isHome {
    display: none;
  }

  .statMainContainer .heading_container .heading {
    font-size: 5rem;
  }

  .statMainContainer .statContainer {
    flex-direction: row;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .statMainContainer .heading {
    text-align: center;
    font-size: 3rem;
    font-family: "Merriweather", serif;
    font-weight: 700;
    margin-bottom: 5rem;
  }

  .statMainContainer .statContainer .statItem {
    display: flex;
    gap: 1rem;
    padding-left: 2rem;
    padding-right: 3rem;
    align-items: center;
    padding-top: 0rem;
    padding-bottom: 0rem;
    flex-direction: column;
    width: 25%;
  }

  .statMainContainer .statContainer .statItem p {
    margin: 0;
  }

  .statMainContainer .statContainer .statItem.statMiddleItem1 {
    border-top: none;
    border-bottom: none;
    border-right: solid 1px #dbdbdb;
    border-left: solid 1px #dbdbdb;
  }

  .statMainContainer .statContainer .statItem.statMiddleItem2 {
    border-bottom: none;
    border-right: solid 1px #dbdbdb;
  }

  .statMainContainer .statContainer .statItem .stat {
    width: 65%;
    text-align: left;
    /* color: #0077b5; */
    font-size: 5rem;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
  }

  .statMainContainer .statContainer .statItem .info {
    font-size: 1.5rem;
    width: 65%;
    font-weight: 300;
  }

  .statMainContainer .statContainer .statItem .info.first {
    /* align-items: flex-start; */
    width: 65%;
    margin: auto;
    padding-right: 2rem;
  }

  .statMainContainer .statContainer .statItem.custom {
    margin-left: 2rem;
  }

  .statMainContainer .statContainer .statItem .stat.custom {
    width: 100%;
  }

  .statMainContainer .statContainer .statItem .info.custom {
    width: 100%;
  }
}
