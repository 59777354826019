.container {
}

.lg {
  display: none;
}

.container .headingContainer {
  /* background-color: #0177b5; */
  padding: 2.5rem;
  font-family: "Merriweather";
}

.container .headingContainer .heading {
  /* color: white; */
  font-size: 2.5rem;
  font-weight: 500;
}

.container .contentContainer {
  padding: 0rem 3rem 3rem 3rem;
}

.container .contentContainer .text {
  font-size: 1.5rem;
}

.container .contentContainer .card {
  margin-top: 3rem;
}

.container .contentContainer .card .cardHeading {
  font-weight: 600;
  font-size: 1.7rem;
}

.container .contentContainer .card .smallText {
  font-weight: 400;
  font-size: 1.7rem;
}

.container .contentContainer .text.last {
  margin-top: 4rem;
}

@media screen and (min-width: 960px) {
  .container .headingContainer {
    padding: 4rem 12rem;
  }

  .lg {
    display: block;
  }

  .sm {
    display: none;
  }
  .container .headingContainer .heading {
    font-size: 4rem;
    font-weight: 500;
  }

  .container .contentContainer {
    padding: 0rem 12rem 6rem 12rem;
    width: 90%;
  }

  .container .contentContainer .text {
    font-size: 2rem;
  }

  .container .contentContainer .card {
    margin-top: 3rem;
  }

  .container .contentContainer .card .cardHeading {
    font-weight: 600;
    font-size: 2.2rem;
  }
}
