.container {
  background-color: #e5f1f7;
  padding: 4rem 2rem;
}

.lg {
  display: none;
}

.container .titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .titleContainer .title {
  font-family: "Merriweather";
  font-weight: 700;
  font-size: 2.5rem;
}

.container .chartHeaders {
  margin-top: 2rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-size: 1.3rem;
  gap: 0rem;
}

.container .chartHeaders .chartHeaderItem {
  display: flex;
  gap: 2rem;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  /* justify-content: ; */
}

.container .chartHeaders .chartHeaderItem.first {
  margin-bottom: 0rem;

  color: #01486d;
}

.container .chartHeaders .chartHeaderItem.second {
  margin-bottom: 0rem;

  color: #0077b5;
}

.container .chartHeaders .chartHeaderItem .statCircle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.container .chartHeaders .chartHeaderItem.first .statCircle {
  background-color: #01486d;
}

.container .chartHeaders .chartHeaderItem.second .statCircle {
  background-color: #0077b5;
}

.container .imageContainer {
  margin-top: 6rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.container .imageContainer .image {
  width: 100%;
}

@media screen and (min-width: 960px) {
  .sm {
    display: none;
  }

  .lg {
    display: block;
  }

  .container {
    padding: 8rem 12rem;
  }

  .container .titleContainer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .container .titleContainer .title {
    font-family: "Merriweather";
    font-weight: 700;
    font-size: 3rem;
    /* width: 60%; */
  }

  .container .chartHeaders {
    margin-top: 0rem;
    width: auto;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    font-size: 1.7rem;
    gap: 3rem;
    align-items: flex-end;
  }

  .container .imageContainer {
    margin-top: 8rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
