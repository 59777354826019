.container {
  padding: 3rem;
}

.container .heading {
  /* display: inline-flex; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Merriweather", sans-serif;
  font-size: 2.3rem;
  font-weight: 600;
}

.container .heading p {
  position: relative;
  /* display: block; */
}

.container .heading .underlined {
  position: relative;
}

.container .heading .underlined::after {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 5px;
  background-color: #0077b5;
  bottom: -6px;
  display: inline-block;
  border-radius: 1px;
}

.container .cardContainer {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.container .cardContainer .cardImg {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  border-radius: 10px;
}

.container .cardContainer .first .cardImg {
  background-image: url("../../assets/bg/building.png");
}

.container .cardContainer .second .cardImg {
  background-image: url("../../assets/bg/handshake.png");
}

.container .cardContainer .third .cardImg {
  background-image: url("../../assets/bg/laptop.png");
}

.container .cardContainer .cardImg .headingSm {
  color: white;
  font-size: 2.5rem;
}

.container .cardContainer .headingLg {
  display: none;
}

.container .cardContainer .second .cardImg .headingSm {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.container .cardContainer .third .cardImg .headingSm {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 50%;
}

.container .cardContainer .first .cardImg .headingSm {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 4rem 12rem 8rem 12rem;
  }

  .container .heading {
    font-size: 4rem;
    display: flex;
    gap: 17px;
    justify-content: center;
  }

  .container .heading .underlined::after {
    bottom: -1px;
  }

  .container .cardContainer {
    flex-direction: row;
    gap: 6rem;
    /* width: 0%; */
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .container .cardContainer .card {
    width: 387px;
  }

  .container .cardContainer .cardImg {
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    border-radius: 10px;
  }

  .container .cardContainer .cardImg .headingSm {
    display: none;
  }

  .container .cardContainer .headingLg {
    display: block;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;
  }

  .container .cardContainer .second .headingLg {
    width: 60%;
  }

  .container .cardContainer .third .headingLg {
    width: 30%;
  }

  .container .cardContainer .first .headingLg {
    width: 30%;
  }
}
