.hero {
  padding: 20rem 3rem 3rem 1rem;
  min-height: 50vh;
  display: flex;
  justify-items: flex-end;
  background-image: url("../../assets/bg/home-hero-sm.png");
  background-size: cover;
  position: relative;
}

.myVideo {
  position: absolute;
  top: 0;
  left: 0;
  /* min-width: 100%; */
  width: 100%;
  height: 100%;
  display: block;
  object-fit: fill;
}

.hero .title {
  font-size: 3rem;
  color: white;
  /* width: 95%; */
  font-family: "Merriweather", sans-serif;
  font-weight: 500;
  margin-bottom: 2rem;
}

.hero .textsm {
  color: white;
  font-size: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 80%;
  margin-left: 2.5rem;
  font-weight: 300;
}

.hero .textlg {
  display: none;
}

.hero .textContainer {
  position: relative;
}

.hero .textBar {
  position: absolute;
  height: 100%;
  width: 5px;
  background: #0277b5;
  border-radius: 50px;
}

/* .hero .discoverBtnContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
} */

.hero .discoverBtn p {
  margin: 0;
  font-size: 1.8rem;
  font-size: 500;
}

.hero .discoverBtn {
  display: flex;
  border: none;
  border-radius: 10px;
  padding: 1rem 3rem;
  align-items: center;
  color: #01486d;
  background-color: white;
  gap: 1rem;
  font-weight: 600;
}

@media screen and (min-width: 960px) {
  .hero {
    padding: 4rem 12rem 15rem 12rem;
    min-height: 80vh;
    display: flex;
    background-image: url("../../assets/bg/home-hero-lg.png");
    background-size: cover;
    align-items: flex-end;
  }

  .hero .title {
    font-size: 5rem;
    font-weight: 700;
    width: 65%;
  }

  .hero .textlg {
    font-size: 1.7rem;
    width: 50%;
    display: block;
    color: white;

    margin-top: 3rem;
    margin-bottom: 0rem;
    margin-left: 2rem;
    font-weight: 300;
  }
  .hero .textsm {
    display: none;
  }
}
