.container .heading {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
  font-weight: 600;
}

.container .message {
  font-size: 1.5rem;
  line-height: 2.8rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 0.5rem 1rem 0.5rem 3rem;
    border-left: solid 1.3px #c5bebe;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8rem;
  }

  .container.first {
    border-left: none;
  }

  .container .message {
    font-size: 1.5rem;
    line-height: 2.3rem;
  }

  .container .heading {
    font-weight: 500;
  }
}
