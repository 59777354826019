.container {
  padding: 5rem 2rem 5rem 2rem;
}

.container .titleLg {
  display: none;
}

.container .titleSm {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 4rem 20rem;
  }
}
