.hero {
  width: 100%;
  height: 70vh;
  position: relative;
  padding: 5rem 2rem 4rem 2rem;
}

.myVideo {
  position: absolute;
  top: 0;
  left: 0;
  /* min-width: 100%; */
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.hero .contentSection {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.hero .title {
  font-family: "Merriweather";
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 4.8rem;
}

.hero .subtitle {
  font-size: 1.8rem;
  margin-top: 2rem;
  width: 70%;
}

.subtitleContainer .subtitle {
  margin-top: 0.4rem;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .hero {
    padding: 6rem 12rem;
  }

  .hero .title {
    font-family: "Merriweather";
    font-weight: 700;
    font-size: 4.5rem;
    line-height: 4.8rem;
    margin-top: 2rem;
  }

  .hero .contentSection {
    flex-direction: row;
  }

  .hero .subtitle {
    font-size: 1.8rem;
    margin-top: 2rem;
    width: 70%;
  }

  .hero .subtitle {
    width: 100%;
  }

  .subtitleContainer .subtitle {
    margin-top: 0.4rem;
    width: 100%;
  }
}
