.container {
  padding: 3rem 2rem;
}

.container .heading {
  font-family: "Merriweather", sans-serif;
  color: #01486d;
  font-size: 2.5rem;
  font-weight: 600;
}

.container .subheading {
  font-size: 1.6rem;
  margin-bottom: 3rem;
}

.container .imgContainer .imgHeading {
  display: none;
}

.container .imgContainer .imgContent {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

.container .imgContainer .imgContent .imgSection {
  width: 100%;
  height: 200px;
}

.container .imgContainer .imgContent .imgSection img {
  width: 100%;
  height: 100%;
}

.container .mainContent .mainContentHeading {
  color: #01486d;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.container .mainContent .mainContentDescription {
  font-size: 1.6rem;
  line-height: 3rem;
  color: black;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 5rem 12rem;
  }

  .container .heading {
    font-size: 3.5rem;
  }

  .container .subheading {
    font-size: 1.8rem;
    margin-bottom: 5rem;
  }

  .container .imgContainer .imgHeading {
    display: block;
    font-size: 2rem;
    font-family: "Merriweather", sans-serif;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .container .imgContainer .imgContent {
    gap: 6rem;
    flex-direction: row;
    align-items: center;
  }

  .container .imgContainer .imgContent .imgSection {
    width: 800px;
    height: 300px;
  }

  .container .mainContent .mainContentHeading {
    font-size: 2.8rem;
    width: 70%;
  }

  .container .mainContent .mainContentDescription {
    font-size: 2rem;
    width: 80%;
  }
}
