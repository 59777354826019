.navbar {
  width: 100%;
  display: flex;
  background: #01486d;
  justify-content: space-between;
  padding: 2rem 1rem;
  align-items: center;
}

.navbar .logo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .logo .logoContainer {
  width: 90%;
  display: flex;
  align-items: center;
}

.navbar .navLink {
  display: none;
}

.navbar .toggleIcon {
  width: 50%;
}

@media screen and (min-width: 960px) {
  .navbar {
    padding: 2rem 12rem;
    align-items: center;
    justify-content: space-between;
    background-color: #01486d;
    color: white;
  }
  .navbar .logo {
    width: 15%;
    align-items: flex-center;
  }
  .navbar .navLink {
    display: flex;
    /* width: 60%; */
    justify-content: space-between;
    align-items: center;
  }

  .navbar .navLink .linkItem {
    color: white;
    font-size: 1.6rem;
    text-decoration: none;
    font-weight: 500;
  }

  .popover {
    background-color: #0077b5;
    padding: 4rem;
    min-width: 800px;
    border-radius: 1rem;
  }

  .popover .subContainer .title {
    font-size: 1.7rem;
    color: #01486d;
    font-weight: 600;
  }

  .popover .allServices {
    display: flex;
    justify-content: flex-end;
  }

  .popover .allServices p {
    font-size: 1.6rem;
    color: white;
    text-decoration: none;
  }

  .popover .allServices p a {
    color: white;
  }

  .popover .subContainer .linkContainer {
    display: flex;
    margin-top: 1rem;
    gap: 1rem 0rem;
    flex-wrap: wrap;
  }

  .popover .subContainer .linkContainer .link {
    width: 50%;
    color: white;
    font-size: 1.6rem;
    position: relative;
  }

  .underlined a {
    position: relative;
  }

  .underlined a::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background-color: white;
    left: 0;
    bottom: -4px;
    border-radius: 10px;
  }

  .navbar .navLink .linkItem.active {
    color: #0077b5 !important;
  }

  .navbar .navLink .middleMenuLinks {
    display: flex;
    gap: 4rem;
    align-items: center;
  }

  .navbar .navLink .middleMenuLinks .links {
    position: relative;
  }

  .navbar .navLink .middleMenuLinks .links::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background-color: white;
    left: 0;
    bottom: 0;
    border-radius: 10px;
  }

  .navbar .toggleIcon {
    display: none;
  }
  .navbar .toggleContainer {
    display: none;
  }

  .navbar .navLink .actionMenu {
    display: flex;
    gap: 3rem;
    align-items: center;
  }
  .navbar .navLink .actionMenu .clientLogin {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .navbar .navLink .actionMenu .demoBtn {
    padding: 1rem 2rem;
    background-color: white;
    color: #01486d;
    font-weight: 700;
    border-radius: 8px;
    border: none;

    font-size: 1.5rem;
  }
}
