.container {
  padding: 5rem 2rem 5rem 2rem;
  position: relative;
}

.container .heading {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  font-weight: 600;
  font-family: "Merriweather";
}

.container .cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  gap: 2rem;
}

.container .cardContainer .card {
  display: flex;
  gap: 2rem;
  padding: 2rem 3rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  align-items: center;
}

.container .cardContainer .card .iconContainer {
  display: flex;
  background-color: #01486d;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}

.container .cardContainer .card .cardText {
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
  font-weight: 500;
}

.cardLg {
  display: none;
}

.myVideo {
  display: none;
  min-width: 0;
  width: 0;
  height: 0;
}

.container .demoContainer {
  /* display: none; */
}

.container .demoContainer {
  display: flex;
  margin-top: 0rem;
  justify-content: center;
}

.container .demoBtn {
  display: flex;
  border: none;
  border-radius: 30px;
  padding: 1rem 2rem;
  align-items: center;
  background-color: white;
  color: #0077b5;
  gap: 1rem;
  font-size: 500;
  font-size: 1.5rem;
  font-weight: 600;
}

.container .demoBtn svg {
  width: 16px;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 6rem 12rem;
    /* background-image: url("../../assets/bg/services-bg.png"); */
  }

  .container .heading {
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-family: "Merriweather";
  }

  .container .cardContainer {
    /* display: none; */
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 4rem 6rem;
    border-radius: 10px;
    margin-top: 0;
  }

  .container .card .iconContainer svg {
    color: white;
  }

  .container .cardText {
    width: 80% !important;
    text-align: left !important;
    font-size: 17px !important;
  }

  /* .cardLg {
    display: flex;
    justify-content: space-between;
  } */

  /* .container .cardLg .card .iconContainer {
    display: flex;
    background-color: #0277b5;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    margin: 0 auto;
  } */

  /* .container .cardLg .card {
    padding: 2rem 3rem;
    border-radius: 10px;
    align-items: center;
    width: 30%;
    position: relative;
  } */

  /* .container .cardLg .card .cardText {
    font-size: 1.3rem;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    font-weight: 400;
  } */

  /* .container .cardLg .card .cardTitle {
    font-size: 1.8rem;
    text-align: center;
    margin-top: 2rem;
    width: 100%;
  } */

  .container .demoContainer {
    display: flex;
    margin-top: 0rem;
    justify-content: center;
  }

  .container .demoBtn {
    display: flex;
    border: none;
    border-radius: 30px;
    padding: 1rem 2rem;
    align-items: center;
    background-color: white;
    color: #0077b5;
    gap: 1rem;
    font-size: 500;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .container .demoBtn svg {
    width: 16px;
  }

  .container .cardLg .card.line::after {
    position: absolute;
    width: 300px;
    height: 0px;
    left: 65%;
    top: 25%;

    border: 1px solid #c5bebe;
    content: "";
  }

  .myVideo {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
    /* height: ; */
  }
}
