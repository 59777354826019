.wrapper{
    margin-bottom: 60px;
}

.iconContainer svg{
    width: 100px;
    height: auto;
}

.wrapper h5{
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 43px;
    color: #01486D;
}

.wrapper p{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    max-width: 80%;
}

.tagContainer{
    background: #CCE4F0;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 35px;
    color: #0077B5;
    padding: 0px 15px;
    width: fit-content;
}

@media (max-width: 959px) {
    .iconContainer svg{
        width: 70px;
        height: auto;
    }

    .wrapper{
        margin-bottom: 0px;
    }

    .wrapper h5{
        font-size: 18px;
        line-height: 33px;
    }
    .wrapper p{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 40px;
        max-width: 100%;
    }

    .tagContainer{
        border-radius: 5px;
        font-size: 14px;
        line-height: 25px;
        padding: 0px 10px;
        margin-bottom: 10px;
    }
}

