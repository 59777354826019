.wrapper{
    margin-bottom: 60px;
}

.imageContainer{
    width: 100%;
    height: 493px;
    /* border-radius: 5px; */
}

.wrapper a{
    text-decoration: none;
}

.wrapper a:hover{
    text-decoration:underline;
    text-decoration-color: #01486D;
}

.wrapper h3{
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 43px;
    color: #01486D;
}

.wrapper p{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    max-width: 80%;
}

.wrapper h5{
    color: #9C9C9C;
    font-size: 19px;
    font-family: Lato;
    font-weight: 400;
    line-height: 25px;
    /* margin: 0; */
}

.subImageContainer{
    height: 78px;
    width: 78px;
    border-radius: 100px;
    background: #F5F5F5;
}

.newWrapper{
    margin-top: 50px;
}

@media (max-width: 959px) {
    .iconContainer svg{
        width: 70px;
        height: auto;
    }

    .wrapper{
        margin-bottom: 0px;
    }

    .wrapper h3{
        font-size: 18px;
        line-height: 33px;
    }

    .wrapper h5{
        font-size: 15px;
        line-height: 20px;
    }
    .wrapper p{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 40px;
        max-width: 100%;
    }

    .tagContainer{
        border-radius: 5px;
        font-size: 14px;
        line-height: 25px;
        padding: 0px 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .imageContainer{
     margin-bottom: 30px;
    }
}

@media (max-width: 480px) {
    .imageContainer{
        height: 293px;
    }

    .subImageContainer{
        height: 58px;
        width: 58px;
    }
}

