.container {
  padding: 3rem 2rem;
}

.container .heading {
  font-family: "Merriweather", sans-serif;
  font-size: 3rem;
  display: none;
}

.container .card {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: solid 1px #c5bebe;
}

.container .card a {
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  gap: 2rem;
}

.container .card .img {
  width: 120.61px;
  height: 80px;
}

.container .card .description {
  display: none;
}

.container .card .title {
  color: #01486d;
  font-weight: 600;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 3rem 12rem;
  }

  .container .heading {
    font-family: "Merriweather", sans-serif;
    font-size: 3rem;
    display: block;
    font-weight: 600;
  }

  .container .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .container .card {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: none;
    width: 30%;
  }

  .container .card a {
    flex-direction: column;
    align-items: flex-start;

    gap: 1rem;
  }

  .container .card .description {
    display: block;
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  .container .card .img {
    width: 100%;
    height: 250px;
  }

  .container .card .title {
    color: #01486d;
    font-weight: 600;
  }

  .container .card .cardContent {
    margin-top: 1rem;
  }
}
