.container {
  padding: 2rem 3rem 2rem 3rem;
  display: flex;
  justify-content: center;
}

.container .logoContainer {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.container .logoContainer .logo {
  /* width: 60%; */
  display: none;
}

.container .logoContainer .logoSm {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 3rem 8rem;
    display: block;
  }
  .container .logoContainer {
    width: 25%;
    margin-left: 0;
  }

  .container .logoContainer .logo {
    width: 60%;
    display: block;
  }

  .container .logoContainer .logoSm {
    display: none;
  }
}
