.container {
  padding: 0rem 2rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 0rem 7rem 10rem 7rem;
    display: flex;
    gap: 4rem;
  }
}
