.page {
  background-color: white;
  min-height: 100vh;
}

@media screen and (min-width: 960px) {
  .page {
    background-color: #00486d;
  }

  ._footer {
    display: none;
  }
}
