.container {
  /* height: 25rem; */
  padding: 4rem 1rem;
  position: relative;
}

.container .info.lg {
  display: none;
}

.container .heading {
  font-size: 2.5rem;
  color: #01486d;
  font-weight: 400;
  line-height: 5.4rem;
  font-family: "Merriweather";
  font-weight: 600;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 14rem 12rem;
    /* background-image: url("../../assets/bg/Prime\ Bridge\ -\ Services\ \(Desktop\).png"); */
    /* height: 40rem; */
    text-align: center;
    background-size: cover;
  }

  .container .heading {
    font-size: 5rem;
    font-weight: 500;
  }

  .container .info.lg {
    display: block;
    text-align: center;
    margin-top: 2rem;
    font-size: 2.3rem;
  }

  .container .infoContainer {
    width: 45%;
    min-height: 20rem;
    margin-left: auto;
    margin-right: 0;
    padding: 3rem;
    position: relative;
    height: auto;

    transform: translateY(10%);
  }

  .container .infoContainer .heading {
    font-size: 3.5rem;
    color: white;
    font-weight: 400;
    line-height: 5.4rem;
    display: block;
    width: 55%;
  }
}
