.container {
  padding: 4rem 2rem;
}

.container .title {
  text-align: center;
  font-family: "Merriweather";
  font-weight: 700;
  font-size: 2.4rem;
  margin-bottom: 4rem;
}

.container .sectionTitle {
  color: #01486d;
  font-weight: 700;
}

.container .sectionTitle.additionalService {
  margin-top: 5rem;
}

.container .cardContainer .rowCard {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  align-items: center;
  margin-top: 2rem;
}

.container .cardContainer .rowCard svg {
  width: 30px;
}

.workContainer {
  padding: 1rem 2rem 5rem 2rem;
  background: white;
  margin-top: 3rem;
  /* position: absolute; */
  top: 0;
  right: 10%;
  /* transform: translateY(-25%); */
}

.workContainer .heading {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  font-weight: 600;
  font-family: "Merriweather";
}

.workContainer .cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  gap: 2rem;
}

.workContainer .cardContainer .card {
  display: flex;
  gap: 2rem;
  padding: 2rem 3rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  align-items: center;
}

.workContainer .cardContainer .card .iconContainer {
  display: flex;
  background-color: #01486d;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.workContainer .cardContainer .card .cardText {
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
  font-weight: 500;
}

.cardLg {
  display: none;
}

.workContainer .demoContainer {
  display: flex;
  margin-top: 0rem;
  justify-content: center;
}

.workContainer .demoBtn {
  display: flex;
  border: none;
  border-radius: 30px;
  padding: 1rem 2rem;
  align-items: center;
  background-color: white;
  color: #0077b5;
  gap: 1rem;
  font-size: 500;
  font-size: 1.8rem;
  font-weight: 600;
}

.workContainer .demoBtn svg {
  width: 16px;
}

.workContainer .card .iconContainer svg {
  width: 18px;
}



@media screen and (min-width: 960px) {
  .container {
    padding: 8rem 12rem;
  }

  .container .title {
    text-align: left;

    font-size: 3rem;
    margin-bottom: 7rem;
  }

  .container .sectionTitle {
    color: #01486d;
    font-weight: 700;
  }

  .workContainer .card .iconContainer svg {
    width: 24px;
  }

  .container .sectionTitle.additionalService {
    margin-top: 5rem;
  }



  .container .flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .workContainer {
    padding: 0rem 0rem;
    max-width: 500px;
    border-radius: 10px;

    /* dis */
    /* background-image: url("../../assets/bg/services-bg.png"); */
  }

  .workContainer {
    padding: 0rem;
    background: white;
    margin-top: 0;
  }

  .workContainer .heading {
    font-size: 3rem;
    margin-bottom: 0rem;
    font-weight: 600;
    font-family: "Merriweather";
  }

  .workContainer .cardContainer {
    /* display: none; */
    /* width: 80%; */
    margin-left: 0;
    margin-right: 0;
    background-color: #f9f9f9;
    padding: 4rem 3rem;
    border-radius: 10px;
  }

  .workContainer .cardContainer {
    margin-top: 0rem;
  }

  .workContainer .card .iconContainer svg {
    color: white;
  }

  .workContainer .cardText {
    width: 80% !important;
    text-align: left !important;
    font-size: 17px !important;
  }

  .workContainer .demoContainer {
    display: flex;
    margin-top: 0rem;
    justify-content: center;
  }

  .workContainer .demoBtn {
    display: flex;
    border: none;
    border-radius: 30px;
    padding: 1rem 2rem;
    align-items: center;
    background-color: transparent;
    color: #0077b5;
    gap: 1rem;
    font-size: 500;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .workContainer .demoBtn svg {
    width: 16px;
  }

  .workContainer .cardLg .card.line::after {
    position: absolute;
    width: 300px;
    height: 0px;
    left: 65%;
    top: 25%;

    border: 1px solid #c5bebe;
    content: "";
  }

  .workContainer .cardContainer .card {
    background-color: white;
  }

  .container .sectionTitle.additionalService {
    margin-top: 8rem;
  }

 
}

@media (max-width: 767px) {
  .servicesContainer{
    margin-left: 30px;
  }
}
