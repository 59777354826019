.container {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
}

.container .title {
  font-family: "Merriweather";
  font-size: 2.3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.lg {
  display: none;
}

.container .subtitle {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.6rem;
}

.container .chartHeaders {
  margin-top: 4rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.container .chartHeaders .chartHeaderItem {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-size: 1.6rem;
  /* justify-content: ; */
}

.container .chartHeaders .chartHeaderItem.first {
  margin-bottom: 1rem;
}

.container .chartHeaders .chartHeaderItem .statCircle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.container .chartHeaders .chartHeaderItem.first .statCircle {
  background-color: #01486d;
}

.container .chartHeaders .chartHeaderItem.second .statCircle {
  background-color: #0077b5;
}

.container .imgContainer {
  margin-top: 3rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.container .imgContainer .image {
  width: 100%;
}

.container .chartFooter {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 4rem;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.container .chartFooter .statsBtn {
  display: block;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.6rem;
}

.container .chartFooter .statsBtn.first {
  background-color: #01486d;
}

.container .chartFooter .statsBtn.second {
  background-color: #0077b5;
}

.container .chartHeaders .chartHeaderItem.first {
  color: #01486d;
}

.container .chartHeaders .chartHeaderItem.second {
  color: #0077b5;
}

.tabsContainer h5{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.274118px;
  margin: 0;
  color: #000000;
}

.activeBorder{
  width: 60px;
  height: 5px;
  background: #0077B5;
  border-radius: 18.2745px;
}

.selectedItem{
  position: relative;
  color: black;
  font-family: 'Lato';
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 3px;
  border-bottom: 1px solid #000000;
  margin-left: 10px;
}

.selectOption{
  color: black;
  font-family: 'Lato';
  font-size: 15px;
  border-radius: 12px;
  width: 70px;
  padding: 5px 3px 5px 13px;
  top: 35px;
  right: 0px;
  background: #F9F9F9;
  position: absolute;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(12.5px);
}

.inactiveBorder{
  width: 60px;
  height: 5px;
  background:#D9D9D9;
  border-radius: 18.2745px;
}

.returnTitle{
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}

.chartFigures{
  margin-left: 15vw;
  gap: 5vw;
  z-index: 100;
  position: absolute;
  top: 8vh;
  left: 0;
}

.chartFigures24{
  margin-left: 5vw;
  gap: 17vw;
  z-index: 100;
  position: absolute;
  top: 8vh;
  left: 0;
}

.chartFigures23{
  margin-left: 5vw;
  gap: 13vw;
  z-index: 100;
  position: absolute;
  top: 10vh;
  left: 0;
}

.chartFigures .figureContainer, .chartFigures24 .figureContainer, .chartFigures23 .figureContainer{
  font-size: 2.5vw;
  font-weight: 600;
  /* margin-bottom: -100px; */
  text-wrap: nowrap;
  padding-top: 95px;
  align-content: baseline;
}

.chartFigures24 .figureContainer{
  padding-top: 105px;
}

.chartFigures .activeFigure, .chartFigures24 .activeFigure, .chartFigures23 .activeFigure{
  color: #0077B5;
}

@media screen and (min-width: 960px) {
  .lg {
    display: block;
  }

  .sm {
    display: none !important;
  }

  .container {
    padding: 6rem 12rem;
  }

  .container .title {
    font-size: 3rem;

    text-align: left;
  }

  .container .subtitle {
    margin-left: 0;
    margin-right: 0;
    font-size: 1.6rem;
  }

  .container .chartHeaders {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    gap: 5rem;
    margin-top: 5rem;
    font-size: 1.4rem;
  }

  .container .chartHeaders .chartHeaderItem.first {
    margin-bottom: 1rem;
  }

  .container .chartHeaders .chartHeaderItem .statCircle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }

  .container .chartHeaders .chartHeaderItem.first .statCircle {
    background-color: #01486d;
  }

  .container .chartHeaders .chartHeaderItem.second .statCircle {
    background-color: #0077b5;
  }

  .container .imgContainer {
    margin-top: 4rem;
    width: 80%;
    /* margin-left: 10rem;
    margin-right: 6rem; */
  }

  .container .chartHeaders .chartHeaderItem {
    margin-left: 0;
    margin-right: 0;
    /* justify-content: ; */
  }

  .container .chartHeaders .chartHeaderItem.first {
    margin-bottom: 0rem;
  }


}

