.mainContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
}

.mainContainer .mainIconContainer {
  display: none;
}

.mainContainer .mainIconContainer svg {
  width: 50%;
}

/* .mainContainer .contentSection {
  width: calc(100% - 60px);
} */

.mainContainer .contentSection .contentIconSection {
  display: flex;
  align-items: center;

  color: #01486d;
  font-weight: 600;
  font-size: 1.4rem;
  gap: 1rem;
  margin-bottom: 1rem;
}

.mainContainer .contentSection .contentIconSection p {
  margin-bottom: 0;
}

.mainContainer .contentSection .contentIcon {
  width: 50px;
  height: 50px;
  background-color: #01486d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.mainContainer .contentSection .contentIcon svg {
  width: 50%;
}

.isoffShore svg {
  width: 40% !important;
}

.mainContainer .contentSection .content {
  font-size: 1.4rem;
  margin-top: 1.7rem;
}

@media screen and (min-width: 960px) {
  .mainContainer {
    justify-content: flex-start;
    gap: 1.5rem;
  }
  .mainContainer .mainIconContainer {
    width: 50px;
    height: 50px;
    background-color: #01486d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }

  .mainContainer .contentSection .contentIcon {
    display: none;
  }

  .mainContainer .contentSection .content {
    font-size: 1.8rem;
    margin-top: 0rem;
  }

  .mainContainer .contentSection {
    width: 80%;
  }

  .mainContainer .contentSection .title {
    font-size: 2rem;
  }

  .mainContainer .contentSection .contentIconSection {
    margin-bottom: 0rem;
  }
}
