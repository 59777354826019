.container {
  padding: 0rem 0rem 6rem 0rem;
  /* height: 35rem; */
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.container .heading {
  font-size: 4rem;
  text-align: center;
  font-weight: 600;
  font-family: "Merriweather", sans-serif;
  /* color: white; */
}

.container .additional {
  margin-top: 5rem;
}

.container .cardContainer {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  /* align-items: flex-start; */
}

.container .additional .cardContainer {
  width: 100%;
}

.container .cardLg {
  padding: 4rem;
  text-align: center;
  width: 50%;
  /* height: 100%; */
  /* background-color: #f9f9f9; */
}

.container .additional .cardLg {
  padding: 2rem;
}

.container .cardLg .iconContainer {
  width: 100px;
  height: 100px;
  background-color: #01486d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.container .cardLg .iconContainer svg {
  width: 50%;
}

.container .cardLg .heading {
  font-size: 1.8rem;
  color: #01486d;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.container .cardLg .message {
  font-size: 1.5rem;
  text-align: center;
}

.container .cardLg button {
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;

  font-weight: 600;
  color: #0077b5;
}

.container .cardLg button svg {
  width: 15px;
}

.container .additional .additionalBtn {
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;

  font-weight: 600;
  color: #0077b5;
}

.additionalBtn svg {
  width: 15px;
}
