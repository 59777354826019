.container {
  padding: 4rem 2rem;
}

.container .sectionTitle {
  display: none;
}

.container .heading {
  font-family: "Merriweather", sans-serif;
  /* color: #01486d; */
  font-size: 2rem;
  font-weight: 700;
}

.container .subTitle {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.container .hero {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: solid 1px #c5bebe;
}

.container .image {
  display: none;
}

.container .content {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.container .content .underline {
  text-decoration: underline;
  color: black;
}

.container .contentContainer .title {
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.container .content .contentTitle {
  font-weight: 700;
}

.contentRowContainer {
  display: flex;
  gap: 1rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 6rem 12rem;
  }

  .container .sectionTitle {
    display: block;
    color: #01486d;
    font-family: "Merriweather", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .container .hero {
    display: flex;
    align-items: center;
    border-bottom: none;
    margin-bottom: 4rem;
  }

  .container .image {
    display: block;
    width: 619px;
    height: 310px;
  }

  .container .image img {
    width: 100%;
    height: 100%;
  }

  .container .heading {
    font-size: 3rem;
    width: 100%;
  }

  .container .subTitle {
    font-size: 2rem;
    margin-top: 1rem;
    width: 100%;
  }

  .container .content {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .container .contentContainer .title {
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
}
