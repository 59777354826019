.container {
  padding: 7rem 2rem 5rem 2rem;
  background-color: #e5f1f7;
}

.container .title {
  font-size: 2.2rem;
  text-align: center;
  line-height: 2.3;
  display: block;
}

.container .text {
  background-color: #ffffff;

  padding: 0.5rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 10rem 2rem 7rem 2rem;
    background-color: white;
  }

  .container .title {
    font-size: 4.5rem;
    text-align: center;
    line-height: 1.7;
    display: block;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
  }

  .container .text {
    background-color: #e5f1f7;

    padding: 0.5rem;
  }
}
