.container .heading {
  color: #01486d;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
  font-family: "Merriweather", sans-serif;
}

.container .message {
  font-size: 1.5rem;
  line-height: 2.8rem;
  text-align: left;
  margin-bottom: 2rem;
}

@media screen and (min-width: 960px) {
  .container .heading::before {
    content: "";
    width: 100%;
    display: block;
    bottom: -5px;
    position: absolute;
    margin: 0;
    height: 3px;
    background-color: #0077b5;
    transition: width 1s ease-in-out;
    border-radius: 10px;
  }

  .container {
    /* background-color: #f9f9f9; */
    padding: 3rem;
    border-radius: 10px;
  }

  .container .heading {
    font-size: 1.9rem;
  }

  .container .message {
    font-size: 1.7rem;
  }
}
