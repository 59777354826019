.container {
  background-color: #01486d;
  padding: 4rem 3rem;
}

.container .heading {
  color: white;
  font-size: 2.5rem;
  text-align: center;
  font-family: "Merriweather", sans-serif;
  font-weight: 600;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.contentContainer .imgContainer {
  width: 202px;

  margin-top: 2rem;
}

.contentContainer .imgContainer img {
  width: 100%;
  height: 202px;
}

.contentContainer .contentHeading {
  display: flex;
  justify-content: center;
  color: white;
}

.contentContainer .contentHeading .founderNameSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contentContainer .contentHeading .founderNameSection svg {
  width: 20px;
}

.contentContainer .contentHeading .founderNameSection p {
  font-size: 2rem;
}

.container .contentHeading .viewMore {
  display: none;
}

.container .mainContentContainer {
  margin-top: 2rem;
  color: white;
  font-size: 1.3rem;
}

.container .mainContentContainer .mainContent {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2.8rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 6rem 13rem;
  }

  .container .heading {
    color: white;
    font-size: 3rem;
    text-align: left;
  }

  .contentContainer {
    flex-direction: row;
    gap: 6rem;
    align-items: center;
  }

  .contentContainer .imgContainer {
    width: 260px;

    margin-top: 2rem;
    display: flex;
    align-items: center;
  }

  .contentContainer .imgContainer img {
    width: 200px;
    height: 200px;
  }

  .container .contentHeading .viewMore {
    display: block;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    cursor: pointer;
  }

  .container .contentHeading .viewMore svg {
    width: 7px;
  }
  .contentContainer .contentHeading {
    justify-content: space-between;
  }

  .container .mainContentContainer {
    font-size: 1.6rem;
  }
}
